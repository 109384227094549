<template>
  <div class="selection-tiles">
    <div class="selection-tiles__inner">
      <SelectionTile
        v-for="(item, index) in items"
        v-bind="item"
        :key="`${index}-{title}`"
        class="selection-tiles__tile"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { ISelectionTile } from '@/types/components/SelectionTile';
import SelectionTile from '@/components/molecules/SelectionTile/Index.vue';

export default {
  name: 'SelectionTiles',
  components: { SelectionTile },
  props: {
    items: {
      type: Array as PropType<ISelectionTile[]>,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.selection-tiles {
  @media (min-width: $screen-sm-min) {
    .selection-tiles__inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .selection-tiles__tile {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}

.selection-tiles__tile {
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}
</style>
