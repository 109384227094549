<template>
  <component
    :is="url ? 'a' : 'span'"
    v-bind="linkAttributes"
    class="selection-tile"
    :class="{ 'selection-tiles--disabled': disabled }"
    :data-test-id="generateTestId('selection tile', props.title || 'unknown')"
  >
    <img
      v-if="image"
      :src="image"
      :alt="imageAlt ?? ''"
      class="selection-tile__image"
      :data-test-id="generateTestId('selection tile image', props.title || 'unknown')"
    />
    <div
      class="selection-tile__title"
      :data-test-id="generateTestId('selection tile title', props.title || 'unknown')"
    >
      {{ title }}
    </div>
    <div v-if="subtitle" class="selection-tile__subtitle" data-test-id="selection-tile-subtitle">
      {{ subtitle }}
    </div>
    <div
      v-if="description"
      class="selection-tile__description"
      data-test-id="selection-tile-description"
    >
      {{ description }}
    </div>
  </component>
</template>
<script lang="ts">
export default {
  name: 'SelectionTile',
  compatConfig: { MODE: 3 },
};
</script>
<script setup lang="ts">
import { computed, defineProps } from 'vue';
import { useTestHelpers } from '@/composables/useTestHelpers';

const props = defineProps<{
  url?: string;
  title: string;
  subtitle?: string;
  description?: string;
  image?: string;
  imageAlt?: string;
  disabled?: boolean;
}>();

const { generateTestId } = useTestHelpers();

const linkAttributes = computed(() => {
  if (!props.url) {
    return {};
  }
  return {
    href: props.url,
  };
});
</script>

<style lang="scss" scoped>
.selection-tile {
  position: relative;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 0 9px rgba($shadow-default, 0.34);
  display: block;
  min-height: 330px;
  transition: all 0.2s;
  width: 250px;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 30px 18px;

  &:not(.selection-tiles--disabled):hover {
    border: 1px solid $persian-green;
    box-shadow: 0 0 10px rgba($persian-green, 0.6);
    text-decoration: none;
    transform: scale(1.033);
  }
}

.selection-tiles--disabled {
  cursor: not-allowed;
  background-color: $white-smoke;
  border: 1px solid $platinum;
}

.selection-tile__title {
  color: $charcoal;
  font-size: 18px;
  font-weight: 500;
}

.selection-tile__subtitle {
  color: $charcoal;
  font-size: 15px;
}

.selection-tile__description {
  color: $charcoal;
  font-size: 13px;
  font-weight: lighter;
  margin-top: 15px;
}

.selection-tile__image {
  max-height: 120px;
  margin-bottom: 15px;
}
</style>
